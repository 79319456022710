// @ts-nocheck
import { USER_NOT_ACTIVATED, USER_NOT_FOUND, publicRoutes } from '@/constants';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as msal from '@azure/msal-browser';

declare global {
  var setLoginError: (error: string) => void;
}

const AuthContext = createContext({} as any);

export const useAuth = () => useContext(AuthContext);

function generatePassword(
  length = 20,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
): string {
  const password = Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('');

  return /\d/.test(password) && /[A-Z]/.test(password) && /[a-z]/.test(password)
    ? password
    : generatePassword();
}

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  globalThis.setLoginError = (error: string) => setError(error);

  useEffect(() => {
    if (publicRoutes.includes(router.pathname)) return;
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(setUser)
      .catch(() =>
        router.push({
          pathname: '/login',
          query: {
            ...(window.location.pathname !== '/' && {
              redirect: window.location.pathname,
            }),
          },
        })
      );
  }, []);

  async function login(email: string, password: string, redirectUri = '/') {
    setError(null);
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      setUser(user);
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        router.push(
          `/account/confirm?email=${email}&_code=${encodeURIComponent(
            password
          )}`
        );
        return;
      }
      router.push(redirectUri);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  }

  async function loginWithMicrosoft() {
    setLoading(true);
    setError(null);

    const msalInstance = new msal.PublicClientApplication({
      auth: {
        clientId: '6851bcae-27e5-447e-8a4a-0c2883b01e86',
      },
    });
    await msalInstance.initialize();
    msalInstance.setActiveAccount(null);

    async function authenticateWithMicrosoftToken(
      email: string,
      token: string
    ) {
      try {
        const cognitoUser = await Auth.signIn(email);
        const user = await Auth.sendCustomChallengeAnswer(cognitoUser, token);
        setUser(user);
        setLoading(false);
        router.push('/');
      } catch (e) {
        await handleMicrosoftSsoError(e);
      }
    }

    async function handleMicrosoftSsoError(e) {
      if (e.code === 'UserLambdaValidationException') {
        let message = e.message;
        if (e.message.includes('USER_NOT_FOUND')) {
          message = USER_NOT_FOUND;
        }
        if (e.message.includes('USER_NOT_ACTIVATED')) {
          message = USER_NOT_ACTIVATED;
        }
        setError(message);
        setLoading(false);
        msalInstance.setActiveAccount(null);
        return;
      }

      if (e.code === 'UsernameExistsException') {
        // ignore cognito signup error, attempt to proceed with sign in
        const user = msalInstance.getActiveAccount();
        const email =
          user.idTokenClaims['email'] ??
          user.idTokenClaims['preferred_username'];
        await authenticateWithMicrosoftToken(email, user.idToken);
        return;
      }

      console.log('Sign in with Microsoft failed:', error);
      setError('Sign in with Microsoft failed, please try again');
    }

    const params = {
      scopes: ['user.read', 'email'],
    };

    try {
      await msalInstance.loginPopup(params).then((response) => {
        msalInstance.setActiveAccount(response.account);
      });
      const user = await msalInstance.acquireTokenSilent({
        ...params,
        account: msalInstance.getActiveAccount(),
      });
      const email =
        user.idTokenClaims['email'] ?? user.idTokenClaims['preferred_username'];
      await Auth.signUp({
        username: email,
        password: generatePassword(),
        attributes: {
          name: user.idTokenClaims['name'],
          'custom:msal_id_token': user.idToken,
        },
      });
      await authenticateWithMicrosoftToken(email, user.idToken);
    } catch (e) {
      await handleMicrosoftSsoError(e);
    }

    setLoading(false);
  }

  async function loginWithGoogle() {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  }

  async function logout() {
    await Auth.signOut();
    setUser(null);
    router.push('/login');
  }

  const values = useMemo(
    () => ({
      user,
      login,
      logout,
      error,
      loading,
      loginWithMicrosoft,
      loginWithGoogle,
    }),
    [user, error, loading]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
