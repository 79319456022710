import { UserRole } from '@prisma/client';

// TODO: types incomplete, see https://github.com/aws-amplify/amplify-js/issues/11113
const hasRole = (user: any, permittedRoles: UserRole[]) => {
  const userGroups: UserRole[] =
    user?.signInUserSession?.idToken?.payload?.['cognito:groups'];
  if (!userGroups) return false;
  if (userGroups.includes('SUPERADMIN')) return true;
  return userGroups.some((role) => permittedRoles.includes(role));
};

export { UserRole as roles, hasRole };
