import { trpc } from '@/utils/trpc';
import { useAuth } from './AuthProvider';
import { ChevronRightIcon, FireIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';

export default function UsageInfo() {
  const { user } = useAuth();
  const usage = trpc.user.usage.useQuery(undefined, {
    enabled: !!user,
  });
  const router = useRouter();
  if (usage.data) {
    if (usage.data.hidden) {
      // show points + streak data
      return (
        <div
          className="group -mx-2 my-1 flex items-center justify-between gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-gray-900 hover:cursor-pointer hover:bg-gray-50"
          onClick={() => router.push('/leaderboards')}
        >
          <div className="flex items-center gap-3">
            <div>
              <FireIcon className="h-6 w-6 shrink-0 text-orange-400" />
            </div>
            <div className="flex flex-col">
              <div>
                <strong>{usage.data.points}</strong> points &middot;{' '}
                <strong>{usage.data.currentStreak}d</strong> streak
              </div>
              <div className="text-gray-700">View leaderboards</div>
            </div>
          </div>
          <ChevronRightIcon className="h-5 w-5" />
        </div>
      );
    } else {
      // show remaining credits for the month
      return (
        <div className="relative py-1">
          <div className="mb-2 flex items-center justify-between">
            <div>
              <span className="inline-block rounded-full py-1 text-xs font-semibold uppercase text-blue-600">
                Usage this month
              </span>
            </div>
            <div className="text-right">
              <span className="inline-block text-xs font-semibold text-blue-600">
                {usage.data.used}/{usage.data.total}
              </span>
            </div>
          </div>
          <div className="mb-4 flex h-2 overflow-hidden rounded bg-blue-200 text-xs">
            <div
              style={{ width: `${usage.data.used}%` }}
              className="flex flex-col justify-center whitespace-nowrap bg-blue-500 text-center text-white shadow-none"
            ></div>
          </div>
        </div>
      );
    }
  }
  return null;
}
