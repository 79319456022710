import { UserRole } from '@prisma/client';

export const publicRoutes = [
  '/404',
  '/login',
  '/logout',
  '/account/reset',
  '/account/forgot',
  '/account/confirm',
  '/account/register',
  '/tools/tutor/vr',
];

export const passwordPolicyInfo =
  'Passwords must be at least 8 characters and should include at least 1 number and 1 uppercase letter.';

export const region = 'eu-west-2';
export const userPoolId = 'eu-west-2_yIdXg0RWL';
export const userPoolWebClientId = '6vqdcm81ucsmpus89huv8trt7g';

export const supportEmail = 'info@myschoolai.com';

export const yearGroups = [
  { year: 'EYFS', age: 5 },
  { year: 'Year 1', age: 6 },
  { year: 'Year 2', age: 7 },
  { year: 'Year 3', age: 8 },
  { year: 'Year 4', age: 9 },
  { year: 'Year 5', age: 10 },
  { year: 'Year 6', age: 11 },
  { year: 'Year 7', age: 12 },
  { year: 'Year 8', age: 13 },
  { year: 'Year 9', age: 14 },
  { year: 'Year 10', age: 15 },
  { year: 'Year 11', age: 16 },
  { year: 'Year 12', age: 17 },
  { year: 'Year 13', age: 18 },
];

export const targetAudience = [
  'Student',
  'Governors',
  'Teachers',
  'Parent/Guardian',
] as const;

export const signUpEmailTemplate = (name: string) => `<p>Dear ${name},</p>
<p>Thank you for registering with MySchoolAi! We’re excited to have you on board and can’t wait for you to experience the many benefits our AI-powered platform offers.</p>
<p>Before you can access our suite of tools, we need to verify your account. This process usually takes less than 24 hours, and we’ll send you a welcome email as soon as it’s complete.</p>
<p>In the meantime, why not check out our tools page? You can learn more about each tool and explore their many features. Visit us here: <a href="https://www.myschoolai.com/tools">https://www.myschoolai.com/tools</a>.</p>
<p>Thank you again for joining MySchoolAi. We’re thrilled to have you as part of our community!</p>
<p>Kind regards,<br>MySchoolAi</p>`;

export const activationEmail = (name: string) => {
  return `
    <p>Dear ${name},</p>
    <p>Your school has authorised you to access <a href="https://myschoolai.com">MySchoolAi</a>, a suite of AI tools to revolutionise all aspects of education.</p>
    <p>To access MySchoolAi, please visit <a href="https://dashboard.myschoolai.com">https://dashboard.myschoolai.com</a> and choose to log in with either Microsoft or Google using your academic email.</p>
    <p>Kind regards,<br>MySchoolAi</p>
  `;
};

export const MAX_ACTIONS_PER_MONTH = 100;

export const USER_NOT_FOUND =
  'You must sign up for a free trial first so we can verify your account.';
export const USER_NOT_ACTIVATED =
  'Your account must be activated before you can access MySchoolAi. Contact your school administrator.';

// the average score required for open-ended questions before moving on
export const AVERAGE_SCORE_THRESHOLD = 5;

export const VALID_INVITE_CODES = [
  'MSAEARLYBIRD',
  'MSA-SMID23',
  'MSA-TRACIWR23',
  'MSA-MYPHIZZ23',
];

export const publicRoles = Object.keys(UserRole).filter(
  (role) => role !== UserRole.SUPERADMIN
);

export const OPENAI_DEFAULT_CHAT_MODEL = 'gpt-4o';
export const GROQ_DEFAULT_CHAT_MODEL = 'mixtral-8x7b-32768';
