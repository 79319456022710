import { Amplify, Hub } from 'aws-amplify';
import {
  USER_NOT_ACTIVATED,
  USER_NOT_FOUND,
  region,
  userPoolId,
  userPoolWebClientId,
} from '@/constants';
import Router from 'next/router';

const setLoginError = (error: string) => {
  globalThis.setLoginError(error);
  Router.events.off('routeChangeComplete', setLoginError);
};

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure') {
    let error = data.payload.data.message;
    if (data.payload.data.message.includes('USER_NOT_FOUND')) {
      error = USER_NOT_FOUND;
    }
    if (data.payload.data.message.includes('USER_NOT_ACTIVATED')) {
      error = USER_NOT_ACTIVATED;
    }
    Router.events.on('routeChangeComplete', () => setLoginError(error));
  }
});

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://dashboard.myschoolai.com';

Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: 'myschoolai.auth.eu-west-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: baseUrl,
      redirectSignOut: `${baseUrl}/login`,
      responseType: 'code',
    },
  },
  ssr: true,
});
