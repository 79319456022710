import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BookOpenIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  HomeIcon,
  WrenchIcon,
  XMarkIcon,
  ChatBubbleLeftEllipsisIcon,
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  UserGroupIcon,
  PaperAirplaneIcon,
  IdentificationIcon,
  ClipboardDocumentIcon,
  UserIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
  SpeakerWaveIcon,
  CameraIcon,
  AcademicCapIcon,
  ChartPieIcon,
  FilmIcon,
} from '@heroicons/react/24/outline';
import classNames from '@/utils/classNames';
import { useAuth } from './AuthProvider';
import { publicRoutes } from '@/constants';
import { hasRole, roles } from '@/utils/hasRole';
import { UserRole } from '@prisma/client';
import UsageInfo from './UsageInfo';
import { trpc } from '@/utils/trpc';

type NavItem = {
  name: string;
  href: string;
  icon: typeof HomeIcon;
  roles?: UserRole[];
  promote?: boolean;
  hidden?: boolean;
};

const VISIBLE_TOOL_COUNT = 5;

export default function Layout({ children }: { children: React.ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showAllTools, setShowAllTools] = useState(false);
  const { user } = useAuth();
  const router = useRouter();
  const userInfo = trpc.user.getUserInfo.useQuery(undefined, {
    enabled: !!user,
  });

  const tools: NavItem[] = [
    {
      name: 'Report Assistant',
      href: '/tools/report-assistant',
      icon: DocumentTextIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Lesson Planner',
      href: '/tools/lesson-planner',
      icon: BookOpenIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Letter Home',
      href: '/tools/letter-home',
      icon: EnvelopeIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Image Generation',
      href: '/tools/image-generation',
      icon: CameraIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Question Generator',
      href: '/tools/question-generator',
      icon: QuestionMarkCircleIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Themed Joke',
      href: '/tools/themed-joke',
      icon: SpeakerWaveIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Assembly Creator',
      href: '/tools/assembly',
      icon: UserGroupIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Thought of the Day',
      href: '/tools/thought-of-the-day',
      icon: LightBulbIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Behaviour Report',
      href: '/tools/behaviour-report',
      icon: UserIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Trip Organiser',
      href: '/tools/trip-organiser',
      icon: PaperAirplaneIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'SEND Passport',
      href: '/tools/send-passport',
      icon: IdentificationIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Parent Meeting Planner',
      href: '/tools/student-parent-agenda',
      icon: ClipboardDocumentIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    // {
    //   name: 'DfE Query',
    //   href: '/tools/dfe-query',
    //   icon: ChatBubbleLeftEllipsisIcon,
    //   roles: [roles.SUPERADMIN],
    //   promote: true,
    // },
    {
      name: 'YouTube Generator',
      href: '/tools/youtube',
      icon: FilmIcon,
      roles: [roles.TEACHER, roles.ADMIN],
      promote: true,
    },
  ];

  const primaryNavigation: NavItem[] = [
    {
      name: 'Dashboard',
      href: '/',
      icon: HomeIcon,
      roles: [roles.TEACHER, roles.ADMIN],
    },
    {
      name: 'Learning Assistant',
      href: '/tools/tutor',
      icon: AcademicCapIcon,
    },
    {
      name: 'My Progress',
      href: '/tools/tutor/my-progress',
      icon: ChartPieIcon,
      roles: [roles.STUDENT],
      hidden: !userInfo.data?.schoolId,
    },
    {
      name: 'Learner Progress',
      href: '/tools/tutor/learner-progress',
      icon: ChartPieIcon,
      roles: [roles.TEACHER, roles.ADMIN],
      hidden: !userInfo.data?.schoolId,
    },
  ];

  const secondaryNavigation: NavItem[] = [
    {
      name: 'Admin Dashboard',
      href: '/admin',
      icon: WrenchIcon,
      roles: [roles.SUPERADMIN],
    },
    {
      name: 'School Admin',
      href: '/school-admin',
      icon: WrenchIcon,
      roles: [roles.ADMIN],
    },
    {
      name: 'Manage Account',
      href: '/account/settings',
      icon: Cog6ToothIcon,
    },
    {
      name: 'Log Out',
      href: '/logout',
      icon: ArrowLeftOnRectangleIcon,
    },
  ];

  const availableTools = tools
    .filter((tool) =>
      tool.roles ? hasRole(user, tool.roles) && !tool.hidden : true
    )
    .sort((a, b) => {
      if (a.promote && !b.promote) {
        return -1;
      } else if (!a.promote && b.promote) {
        return 1;
      } else {
        return 0;
      }
    });
  const visibleTools = showAllTools
    ? availableTools
    : availableTools.slice(0, VISIBLE_TOOL_COUNT);
  const hiddenToolCount = availableTools.length - VISIBLE_TOOL_COUNT;

  if (publicRoutes.includes(router.pathname)) {
    return <Fragment>{children}</Fragment>;
  }

  if (!user) return null;

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="mt-4 flex h-16 shrink-0 items-center">
                    <img
                      className="mx-auto h-14 w-auto"
                      src="/logo.png"
                      alt="site logo"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {primaryNavigation.map((item) => {
                            if (item.roles && !hasRole(user, item.roles))
                              return null;
                            return (
                              <li key={item.name}>
                                <Link
                                  href={item.href}
                                  className={classNames(
                                    router.pathname === item.href
                                      ? 'bg-gray-50 text-blue-600'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      router.pathname === item.href
                                        ? 'text-blue-600'
                                        : 'text-gray-400 group-hover:text-blue-600',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                      {visibleTools.length > 0 && (
                        <>
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">
                              Other tools
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              {visibleTools.map((tool) => {
                                if (tool.roles && !hasRole(user, tool.roles))
                                  return null;
                                return (
                                  <li key={tool.name}>
                                    <Link
                                      href={tool.href}
                                      className={classNames(
                                        router.pathname === tool.href
                                          ? 'bg-gray-50 text-blue-600'
                                          : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                      )}
                                    >
                                      <tool.icon
                                        className={classNames(
                                          router.pathname === tool.href
                                            ? 'text-blue-600'
                                            : 'text-gray-400 group-hover:text-blue-600',
                                          'h-6 w-6 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span
                                        className="truncate"
                                        title={tool.name}
                                      >
                                        {tool.name}
                                      </span>
                                      {tool.promote && (
                                        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                          New
                                        </span>
                                      )}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                          {hiddenToolCount > 0 && (
                            <div className="relative -mt-4">
                              <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true"
                              >
                                <div className="w-full border-t border-gray-300" />
                              </div>
                              <div className="relative flex justify-center">
                                <button
                                  type="button"
                                  className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                  onClick={() => setShowAllTools(!showAllTools)}
                                >
                                  {showAllTools ? (
                                    <ArrowUpCircleIcon
                                      className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ArrowDownCircleIcon
                                      className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {showAllTools
                                    ? 'Show fewer'
                                    : `Show ${hiddenToolCount} more`}
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <li className="my-4 mt-auto">
                        <UsageInfo />
                        <ul role="list" className="-mx-2 space-y-1">
                          {secondaryNavigation.map((item) => {
                            if (item.roles && !hasRole(user, item.roles))
                              return null;
                            return (
                              <li key={item.name}>
                                <Link
                                  href={item.href}
                                  className={classNames(
                                    router.pathname === item.href
                                      ? 'bg-gray-50 text-blue-600'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      router.pathname === item.href
                                        ? 'text-blue-600'
                                        : 'text-gray-400 group-hover:text-blue-600',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="mt-4 flex h-16 shrink-0 items-center">
            <img
              className="mx-auto h-14 w-auto"
              src="/logo.png"
              alt="site logo"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {primaryNavigation.map((item) => {
                    if (item.roles && !hasRole(user, item.roles)) return null;
                    return (
                      <li key={item.name}>
                        <Link
                          href={item.href}
                          className={classNames(
                            router.pathname === item.href
                              ? 'bg-gray-50 text-blue-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              router.pathname === item.href
                                ? 'text-blue-600'
                                : 'text-gray-400 group-hover:text-blue-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
              {visibleTools.length > 0 && (
                <>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      Other tools
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {visibleTools.map((tool) => {
                        if (tool.roles && !hasRole(user, tool.roles))
                          return null;
                        return (
                          <li key={tool.name}>
                            <Link
                              href={tool.href}
                              className={classNames(
                                router.pathname === tool.href
                                  ? 'bg-gray-50 text-blue-600'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                              )}
                            >
                              <tool.icon
                                className={classNames(
                                  router.pathname === tool.href
                                    ? 'text-blue-600'
                                    : 'text-gray-400 group-hover:text-blue-600',
                                  'h-6 w-6 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                              <span className="truncate" title={tool.name}>
                                {tool.name}
                              </span>
                              {tool.promote && (
                                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  New
                                </span>
                              )}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  {hiddenToolCount > 0 && (
                    <div className="relative -mt-4">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center">
                        <button
                          type="button"
                          className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setShowAllTools(!showAllTools)}
                        >
                          {showAllTools ? (
                            <ArrowUpCircleIcon
                              className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownCircleIcon
                              className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                          {showAllTools
                            ? 'Show fewer'
                            : `Show ${hiddenToolCount} more`}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
              <li className="my-4 mt-auto">
                <UsageInfo />
                <ul role="list" className="-mx-2 space-y-1">
                  {secondaryNavigation.map((item) => {
                    if (item.roles && !hasRole(user, item.roles)) return null;
                    return (
                      <li key={item.name}>
                        <Link
                          href={item.href}
                          className={classNames(
                            router.pathname === item.href
                              ? 'bg-gray-50 text-blue-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-blue-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              router.pathname === item.href
                                ? 'text-blue-600'
                                : 'text-gray-400 group-hover:text-blue-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          MySchoolAi
        </div>
      </div>

      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </>
  );
}
