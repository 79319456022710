import '@/lib/amplify';
import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import AuthProvider from '@/components/AuthProvider';
import Layout from '@/components/Layout';
import { trpc } from '@/utils/trpc';
import { Toaster } from 'react-hot-toast';
import Head from 'next/head';
import AlertProvider from '@/components/AlertProvider';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import 'globalthis/polyfill';
import { MantineProvider } from '@mantine/core';

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>MySchoolAi</title>
        <meta
          name="description"
          content="Our mission is to empower educators by providing them with cutting-edge tools that save time, increase efficiency, and enhance the educational experience for students."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <AuthProvider>
        <AlertProvider AlertComponent={ConfirmationDialog}>
          <MantineProvider
            theme={{
              components: {
                MultiSelect: {
                  styles: {
                    searchInput: {
                      boxShadow: 'none',
                    },
                    label: {
                      marginBottom: '0.5rem',
                    },
                  },
                },
                Select: {
                  styles: {
                    label: {
                      marginBottom: '0.5rem',
                    },
                  },
                },
              },
            }}
          >
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </MantineProvider>
        </AlertProvider>
      </AuthProvider>
      <Toaster />
    </>
  );
}

export default trpc.withTRPC(App);
